#vendor-cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.vendor-card{
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 220px;
    margin: 20px;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
}

.vendor-card h3{
    font-weight: 700;
    width: 100%;
    border-bottom: inherit;
    padding: 2px;
}

.vendor-card p{
    font-size: medium;
    font-style: italic;
}

.vendor-card.light{
    border: 1px solid black;
}

.vendor-card.dark{
    border: 1px solid white;
}

.vendor-card.add{
    border: 2px dotted green;
    color: green;
}

