.check-container {
    min-width: 110px;
    max-width: 250px;
    height: 90px;
    margin: 4px;
    padding: 5px;
    border-radius: 25px;
    justify-content: center;
    align-items: center;
    border: 1px solid gray
    
}

.check-container.checked.dark{
    border: 2px solid white;
}

.check-container.checked.light{
    border: 2px solid black;
}

.check span{
    position: absolute;
    display: none;
    color: #fff !important;
}